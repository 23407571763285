<template>
  <div class="members-wrapper">
    <!-- 开通会员 -->
    <div class="members-info">
      <div class="opening-member">
        <div v-if="!isLogin || (userInfo && !userInfo.IsNotMember)">
          <div class="my-member">
            {{
              netBarVIP
              ? `${netBarName}已经帮你开通VIP会员`
              : "网吧未帮你开通VIP会员"
            }}
          </div>

          <h3 v-if="netBarVIP">您可以享受VIP权益</h3>
          <h3 v-else>
            <a href="javascript:;" @click="activateNow">通知网吧管理</a>帮您开通VIP会员，或者自行开通VIP
          </h3>
          <!-- <a class="btn" @click="open(active)"></a> -->
          <div v-if="netBarVIP">
            <a class="tobuy" v-if="netBarVIPEndTime">
              到期时间：{{ netBarVIPEndTime.substr(0, 10) }}
            </a>
          </div>
          <div class="toOpen" v-else>
            <a href="javascript:;" @click="activateNow">
              <i class="el-icon-question"></i>
              网吧管理员如何开通</a>
            <a class="buy" @click="open(active)">立即开通</a>
          </div>
        </div>

        <div v-else>
          <div class="my-member">我的会员</div>
          <h3 class="isVip">您已成为会员</h3>
          <p>
            到期时间：{{
              userInfo && userInfo.MemberExpirationDate.split(" ")[0]
            }}
          </p>
        </div>
      </div>
      <div class="members-box">
        <img src="@/assets/images/members/info.png" alt />
      </div>
    </div>
    <!-- 会员专属特权 -->
    <div class="privileges-info">
      <!-- <div class="btn-box">
        <router-link to="/order" class="order">
          <img src="@/assets/images/members/order-btn.png" alt />
        </router-link>
        <router-link to="/order?activeName=2" class="coupons">
          <img src="@/assets/images/members/order-btn1.png" alt />
        </router-link>
      </div> -->
      <div class="title">
        <img :src="getTheme == 'light'
              ? require('@/assets/images/members/title.png')
              : require('@/assets/images/members/title2.png')
            " alt />
      </div>

      <div class="renewal-box">
        <el-row :gutter="40">
          <el-col :span="8" v-for="(item, index) in memBersList" :key="index">
            <div class="item" :class="{ first: index == 0 }" v-if="index < 3" @click="open(index)">
              <img class="icon" :src="index == 0 ? require('@/assets/images/members/icon.png'):require('@/assets/images/members/mem_icon.png')" alt />

              <div class="content">
                <div class="time">
                  <span>{{ item.Title }}</span>
                  <img v-if="item.GiveDays > 0" src="@/assets/images/members/give.png" alt />
                  <span v-if="item.GiveDays > 0" class="giveDays">{{ item.GiveDays }}天</span>
                </div>
                <p class="price">
                  活动价：
                  <span>{{ item.Price }}元</span>
                </p>
                <p class="original-price">￥{{ (item.Price * 3).toFixed(2) }}</p>
              </div>
              <div class="text">
                <img src="@/assets/images/members/btn.png" alt />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 开通会员弹窗 -->
    <MembersBuy :isShow="isShow" :slectActive="active" @changeShow="showDialogVisible" />

    <!-- 网吧管理员如何开通会员 -->
    <el-dialog custom-class="visible-dialog" :visible.sync="visibleDialog" width="500px" center
      :close-on-click-modal="false" title="网吧管理员如何开通会员?" v-if="netBarInfo">
      <div class="content">
        <div>
          网吧id: {{ netBarId }}
          <span class="copy" v-clipboard:copy="netBarId" v-clipboard:success="onCopy"
            v-clipboard:error="onError">复制</span>
        </div>
        <div>可联系门店网管通知维护人员开通</div>
        <!-- 【{{ netBarInfo.AgencyName }}】 -->

        <div>
          <span v-if="netBarInfo.Auditor == '田琴'">
            或者联系客户经理：{{ netBarInfo.Auditor }}，联系电话：17680602683
            <span class="copy" v-clipboard:copy="'17680602683'" v-clipboard:success="onCopy"
              v-clipboard:error="onError">复制</span>
          </span>
          <span v-else-if="netBarInfo.Auditor == '彭丽君'">或者联系客户经理：{{ netBarInfo.Auditor }}，联系电话：18900763593
            <span class="copy" v-clipboard:copy="'18900763593'" v-clipboard:success="onCopy"
              v-clipboard:error="onError">复制</span>
          </span>
          <span v-else-if="netBarInfo.Auditor == '谭艳'">或者联系客户经理：{{ netBarInfo.Auditor }}，联系电话：18570097832
            <span class="copy" v-clipboard:copy="'18570097832'" v-clipboard:success="onCopy"
              v-clipboard:error="onError">复制</span>
          </span>

          <span v-else>或者联系客户经理：特权市场，联系电话：18975197852
            <span class="copy" v-clipboard:copy="'18975197852'" v-clipboard:success="onCopy"
              v-clipboard:error="onError">复制</span>
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetMemberPackage,
  CrateOrder,
  GetOrderPayState,
  GetNetbarInfo,
} from "@/api";
import { mapGetters } from "vuex";
import QRCode from "qrcode";
import MembersBuy from "./components/MembersBuy.vue";
import cMethods from "@/util/cMethods.js";

export default {
  name: "Members",
  components: {
    MembersBuy,
  },
  computed: {
    ...mapGetters([
      "isLogin",
      "userInfo",
      "netBarId",
      "netBarName",
      "netBarVIP",
      "netBarVIPEndTime",
      "getIsAuth",
      "getTheme",
    ]),
  },
  data() {
    return {
      isShow: false,
      memBersList: [],
      active: 0,
      checked: true,
      vipCount: 0,
      memberObj: {},
      payWay: 1, // 支付方式：1.支付宝支付  2.微信支付
      hover: false,
      qrCode: "",
      first: 0,
      orderCode: "",
      OrderNo: "",
      ExchangeHoverIs: false,
      timer: 0,
      visibleDialog: false,
      netBarInfo: null,
    };
  },
  mounted() {
    this._GetMemberPackage();
    // 统计
    _czc.push(["_trackEvent", "买VIP会员页", "网页", "访问量"]);
    if (this.netBarVIP) {
      _czc.push(["_trackEvent", "钻石版", "VIP会员页", "访问量"]);
    } else {
      _czc.push(["_trackEvent", "黄金版", "VIP会员页", "访问量"]);
    }
  },
  methods: {
    // 获取网吧信息
    async _GetNetbarInfo() {
      let params = {
        netbarId: this.netBarId,
      };
      let res = await GetNetbarInfo(params);

      if (res.Object) {
        this.netBarInfo = res.Object;
      }
    },
    activateNow() {
      if (this.isLogin) {
        this.visibleDialog = true;

        if (this.netBarId) {
          this._GetNetbarInfo();
        }

        _czc.push(["_trackEvent", "我的会员", "通知网吧管理", "点击量"]);
      } else {
        eventBus.$emit("getQrCode");
      }
    },
    // 会员购买弹窗
    showDialogVisible(data) {
      if (data === "false") {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    },
    // 获取会员套餐列表
    async _GetMemberPackage() {
      let params = {};
      let res = await GetMemberPackage();
      if (res.StatusCode == 200) {
        this.memBersList = res.Object;
      }
    },
    // 开通会员弹窗
    open(index) {
      if (this.isLogin) {
        this.active = index;
        this.memberObj = this.memBersList[this.active];
        // 是否绑定手机号
        let phone = sessionStorage.getItem("Phone");

        if (phone) {
          if (this.getIsAuth && !this.userInfo.IDCard) {
            eventBus.$emit("showAuth");
            return false;
          }
          this.active = index;
          this.isShow = true;
          // eventBus.$emit("membersBuy", this.active);
        } else {
          eventBus.$emit("showLoginPhone");
        }
      } else {
        eventBus.$emit("getQrCode");
      }
      _czc.push(["_trackEvent", "买VIP会员页", "付款码", "展现量"]);
    },
    // 复制成功时的回调函数
    onCopy(e) {
      this.$message({
        type: "success",
        message: "内容已复制到剪切板！",
      });
    },
    // 复制失败时的回调函数
    onError(e) {
      this.$message.error("抱歉，复制失败！");
      this.$message({
        type: "error",
        message: "抱歉,复制失败！",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.members-wrapper {
  width: 1040px;
  padding: 20px;

  .members-info {
    width: 1040px;
    height: 230px;
    background: url("./../assets/images/members/bg.jpg") no-repeat;
    background-size: 100% 100%;
    box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }

  .opening-member {
    width: 391px;
    height: 206px;
    float: left;
    margin-top: 12px;
    margin-left: 56px;
    background: url("./../assets/images/members/member.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;

    .my-member {
      margin-top: 30px;
      background: url("./../assets/images/members/title-bg.png") no-repeat;
      background-position: center center;
      color: #feeac5;
      font-size: 18px;
    }

    h3 {
      margin-top: 14px;
      margin-bottom: 40px;
      color: $white-col;
      font-size: 16px;
      // text-align: left;
      line-height: 20px;
      padding: 0 10px;

      // @include font_col6();
      a {
        color: #333;
        text-decoration: underline;
      }
    }

    .btn {
      width: 240px;
      height: 44px;
      display: block;
      margin: 0 auto;
      background: url("./../assets/images/members/btn.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }

    .btn:hover {
      transform: translateY(-2px);
    }

    .toOpen {
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      // color: #fff;
      .buy {
        width: 180px;
        height: 44px;
        line-height: 44px;
        display: block;
        color: #fff;
        background: #ca8b4e;
        border-radius: 22px;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .tobuy {
      width: 240px;
      height: 44px;
      line-height: 44px;
      display: block;
      margin: 0 auto;
      color: #fff;
      background: #ca8b4e;
      border-radius: 22px;
    }

    .isVip {
      margin-top: 40px;
    }

    p {
      font-size: 16px;
      color: $white-col;
    }
  }

  .members-box {
    width: 474px;
    padding: 47px 0;
    margin-left: 60px;
    text-align: center;
    float: left;
  }

  .privileges-info {
    height: 366px;
    margin-top: 20px;
    padding-top: 60px;
    position: relative;
    background-size: 100% 100%;
    text-align: center;
    // background: $white-col;
    @include item_bg_col();
    box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);

    .btn-box {
      position: absolute;
      bottom: 320px;
      right: 30px;
      z-index: 999;

      .order,
      .coupons {
        width: 76px;
        height: 76px;
        border-radius: 38px;
      }
    }

    .title {
      text-align: center;
    }
  }

  .renewal-box {
    padding: 40px 60px;

    .item.active {
      border: 1px solid #c2a36c;
    }

    .item {
      height: 256px;
      @include tag_bg_col();
      position: relative;
      cursor: pointer;
      text-align: center;


      .icon {
        position: absolute;
        top: -7px;
        left: -7px;
      }

      a {
        height: 256px;
        display: block;
        z-index: 9999;
      }

      .time {
        line-height: 25px;
        font-size: 28px;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include font_col3();

        img {
          width: 27px;
          height: 28px;
          margin: 0 10px;
        }

        .giveDays {
          font-size: 28px;
          color: #f02828;
        }
      }

      .price {
        font-size: 22px;
        font-weight: 500;
        color: #f02828;
        margin-bottom: 15px;

        span {
          font-size: 24px;
        }
      }

      .original-price {
        text-decoration: line-through;
        font-size: 18px;
        @include font_col9();
      }

      .text {
        height: 44px;
        line-height: 44px;

        img {
          width: 239px;
          height: 44px;
        }
      }
    }

    .item:hover {
      border: 1px solid #c2a36c;
    }

    .content {
      padding: 40px 0 30px 0;
    }
  }
}
</style>
<style lang="scss">
.visible-dialog {
  .content {
    line-height: 26px;
    font-size: 15px;

    .copy {
      margin-left: 20px;
      background: #ff3636;
      color: #fff;
      font-size: 14px;
      padding: 5px 10px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
</style>